import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { InputType } from "../const/Status";
import { API } from "../const/const";
import { Tag } from "../types/types";
import FormComponent from "../components/FormComponent";

const TagForm: React.FC = () => {
  const [tag, setTag] = useState<Tag>({
    id: -1,
    tag: "",
    created_at: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      axiosService
        .get(`${API}/tags/${id}/`)
        .then((res) => {
          setTag(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  const handleSubmit = (data: any) => {
    const tagData = {
      tag: data.tag,
    };
    if (tagData.tag.trim() === "") return;
    if (id) {
      axiosService
        .put(`${API}/tags/${id}/`, tagData)
        .then(() => {
          navigate("/tags");
        })
        .catch((error) => console.log(error));
    } else {
      axiosService
        .post(`${API}/tags/`, tagData)
        .then(() => {
          navigate("/tags");
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName={id ? "Edytuj" : "Dodaj"}
        fields={[
          {
            name: "tag",
            label: "Tag",
            placeholder: "tu wpisz nazwę taga",
            require: true,
            type: InputType.TEXT,
            value: id ? tag.tag : "",
          },
        ]}
      />
    </div>
  );
};

export default TagForm;
