export const StatusEnum = {
  INACTIVE: "INACTIVE",
  ACTIVE: "ACTIVE",
  PLANNED: "PLANNED",
  FINISHED: "FINISHED",
  DELETED: "DELETED",
};

export const InputType = {
  TEXT: "text",
  DROPDOWN: "dropdown",
  EMAIL: "email",
  PASSWORD: "password",
  NUMBER: "number",
  FILE: "file",
  TIME: "time",
  CHECKBOX: "checkbox",
  TAG: "tag",
  INVALID_ACTIONS: "invalidActions",
  AUDIO: "audio",
};

export const EnumWithId = (enumObject: Record<string, string>) =>
  Object.keys(enumObject).map((key, index) => ({
    id: index,
    name: enumObject[key],
  }));

export const FindElementWithId = (
  enumObject: Record<string, string>,
  name: string
) => EnumWithId(enumObject).find((e) => e.name === name);

// TODO: do refactor
export const StatusOptionWithId = EnumWithId(StatusEnum);

export function isStatus(value: string): value is keyof typeof StatusEnum {
  return value in StatusEnum;
}
