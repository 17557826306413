import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { Link } from "react-router-dom";
import { API } from "../const/const";

interface Audio {
  id: number;
  audio_name: string;
  audio_file: string;
  created_at: string;
}

const AudioList: React.FC = () => {
  const [audioData, setAudioData] = useState<Audio[]>([]);

  const deleteAudio = (id: number) => {
    axiosService
      .delete(`${API}/audios/${id}`)
      .then((res) => {
        setAudioData(audioData.filter((audio) => audio.id !== id));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axiosService
      .get(`${API}/audios`)
      .then((response) => {
        setAudioData(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <h1>Audio Files</h1>
      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Created At</th>
            <th>Audio</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {audioData.map((audio) => (
            <tr key={audio.id}>
              <td>{audio.id}</td>
              <td>{audio.audio_name}</td>
              <td>{audio.created_at}</td>
              <td>{audio.audio_file}</td>
              <td>
                <audio controls>
                  <source src={audio.audio_file} type="audio/mpeg" />
                  Your browser does not support the audio tag.
                </audio>
              </td>
              <td>
                <button onClick={() => deleteAudio(audio.id)}>Delete</button>
                <Link to={`/audio/${audio.id}`}>Edit</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AudioList;
