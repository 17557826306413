import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { InvalidAction } from "../types/types";
import TableComponent from "../components/TableComponent";
import LinkComponent from "../components/LinkComponent";

const InvalidActionList: React.FC = () => {
  const [invalidData, setInvalidData] = useState<InvalidAction[]>([]);

  useEffect(() => {
    axiosService
      .get(`${API}/invalid_actions/`)
      .then((response) => {
        setInvalidData(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/invalid_actions/${id}/`)
      .then((response) => {
        setInvalidData(invalidData.filter((invalid) => invalid.id !== id));
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <TableComponent
        search="name"
        data={invalidData}
        columns={[
          {
            key: "content",
            name: "Nazwa",
            className: "w-2/3",
          },
          {
            key: "lang",
            name: "język",
          },
          {
            key: "created_at",
            name: "created At",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent text="Edycja" url={`/invalid_action/${id}`} />
                <LinkComponent
                  callback={() => handleDelete(id)}
                  text="Usuń"
                  url={`/invalid_actions`}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default InvalidActionList;
