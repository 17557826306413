import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import axiosService from "../utils/axios";
import FilterTableComponent, {
  Column,
  ColumnType,
} from "../components/FilterTableComponent";
import { API } from "../const/const";
import ButtonComponent from "../components/ButtonComponent";

type Header = {
  id: number;
  campaign: string;
  phone: string;
  scenario: string;
  question: string;
  answer: string;
  tags: string[];
  direction: string;
  is_sync: boolean;
};

const header: Column<Header>[] = [
  { name: "Campaign", key: "campaign", withSearching: true, withSorting: true },
  { name: "Phone", key: "phone", withSearching: true, withSorting: true },
  { name: "Scenario", key: "scenario", withSearching: true, withSorting: true },
  { name: "Question", key: "question", withSearching: true, withSorting: true },
  { name: "Answer", key: "answer", withSearching: true, withSorting: true },
  {
    name: "Tags",
    key: "tags",
    withSearching: true,
    withSorting: true,
    type: ColumnType.BADGE,
  },
  {
    name: "Call direction",
    key: "direction",
    withSearching: true,
    withSorting: true,
  },
  { name: "Is sync", key: "is_sync", withSearching: true, withSorting: true },
];

const Result: React.FC = () => {
  const [data, setData] = useState<Header[]>([]);

  useEffect(() => {
    axiosService
      .get(`${API}/results/`)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSync = () => {
    axiosService({
      method: "GET",
      url: `${API}/result_sync/`,
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        saveAs(blob, "candidates.csv");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <ButtonComponent text={"Pobierz wyniki do CSV"} callback={handleSync} />
      <FilterTableComponent header={header} data={data} />
    </div>
  );
};

export default Result;
