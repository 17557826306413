import { Table } from "flowbite-react";
import { Map, Column, FieldType } from "../types/types";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";

interface ITable {
  data: Map[];
  columns: Column[];
  search?: string;
}

const TableComponent = (props: ITable) => {
  const [searchText, setSearchText] = useState("");

  const searchColumn = props.columns.find((c) => c.key === props.search);

  const filteredData = props.data.filter((item) => {
    const columnValue = searchColumn
      ? item[searchColumn.key as keyof Map]
      : item[props.columns[0].key as keyof Map];
    if (columnValue !== undefined) {
      const columnValueStr = columnValue?.toString().toLowerCase();
      const searchValue = searchText.toLowerCase();
      return columnValueStr?.includes(searchValue);
    }
    return false;
  });

  const showText = (text: FieldType): string => {
    const MAX_LENGTH = 20;
    if (!text) {
      return "---";
    }
    const textStr = text.toString();

    return textStr.length > MAX_LENGTH
      ? `${textStr.substring(0, MAX_LENGTH)}...`
      : textStr;
  };

  return (
    <div>
      <form className="pb-4">
        <label
          htmlFor="search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <IoSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="search"
            id="search"
            className="block w-full h-10 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </form>

      <Table hoverable>
        <Table.Head>
          {props.columns.map((c) => (
            <Table.HeadCell key={c.key} className={c.className}>
              {c.name}
            </Table.HeadCell>
          ))}
        </Table.Head>

        <Table.Body className="divide-y">
          {filteredData.map((item) => (
            <Table.Row
              className="bg-white dark:border-gray-700 dark:bg-gray-800"
              key={item.id}
            >
              {props.columns.map((c) => (
                <Table.Cell
                  key={c.key}
                  className={`whitespace-nowrap font-medium text-gray-900 dark:text-white ${c.className}`}
                >
                  {c.onRender ? c.onRender(item.id) : showText(item[c.key])}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default TableComponent;
