import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { InputType } from "../const/Status";
import { API } from "../const/const";
import { InvalidAction } from "../types/types";
import FormComponent from "../components/FormComponent";

const InvalidActionForm: React.FC = () => {
  const [invalidAction, setInvalidAction] = useState<InvalidAction>();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      axiosService
        .get(`${API}/invalid_actions/${id}/`)
        .then((res) => {
          setInvalidAction(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  const handleSubmit = (formData: any) => {
    const invalidActionData: InvalidAction = formData;
    if (id) {
      axiosService
        .put(`${API}/invalid_actions/${id}/`, invalidActionData)
        .then(() => {
          navigate(`/invalid_actions/`);
        });
    } else {
      axiosService
        .post(`${API}/invalid_actions/`, invalidActionData)
        .then(() => {
          navigate(`/invalid_actions/`);
        });
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName={id ? "Zapisz" : "Dodaj"}
        fields={[
          {
            name: "content",
            label: "Nazwa",
            placeholder: "tu wpisz złą akcję",
            require: true,
            type: InputType.TEXT,
            value: id ? invalidAction?.content : "",
          },
          {
            name: "lang",
            label: "Język",
            placeholder: "tu wpisz język",
            require: true,
            type: InputType.TEXT,
            value: id ? invalidAction?.lang : "",
          },
        ]}
      />
    </div>
  );
};

export default InvalidActionForm;
