import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import { EnumWithId, FindElementWithId, InputType } from "../const/Status";
import { API } from "../const/const";
import { CallStatus, Phone, PhoneStatus } from "../types/types";
import FormComponent from "../components/FormComponent";

const PhoneForm: React.FC = () => {
  const [phone, setPhone] = useState<Phone>();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      axiosService
        .get(`${API}/phones/${id}/`)
        .then((res) => {
          setPhone(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  const handleSubmit = (formData: any) => {
    const phoneData = {
      name: formData.name,
      phone: formData.phone,
      status: formData.status.name,
      call_status: formData.callStatus.name,
    };

    if (id) {
      axiosService.put(`${API}/phones/${id}/`, phoneData).then(() => {
        navigate(`/phones`);
      });
    } else {
      axiosService.post(`${API}/phones/`, phoneData).then(() => {
        navigate(`/phones`);
      });
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName={id ? "Edytuj" : "Zapisz"}
        fields={[
          {
            name: "name",
            label: "Nazwa telefonu",
            placeholder: "tu wpisz nazwę telefonu",
            require: true,
            type: InputType.TEXT,
            value: id ? phone?.name : "",
          },
          {
            name: "phone",
            label: "Numer telefonu",
            placeholder: "tu wpisz numer telefonu",
            require: true,
            type: InputType.TEXT,
            value: id ? phone?.phone : "",
          },
          {
            name: "status",
            label: "Status",
            type: InputType.DROPDOWN,
            options: EnumWithId(PhoneStatus),
            value: FindElementWithId(
              PhoneStatus,
              id && phone ? phone.status : PhoneStatus.Inactive
            ),
          },
          {
            name: "callStatus",
            label: "Status dzwonienia",
            type: InputType.DROPDOWN,
            options: EnumWithId(CallStatus),
            value: FindElementWithId(
              CallStatus,
              id && phone ? phone.call_status : CallStatus.COMPLETED
            ),
          },
        ]}
      />
    </div>
  );
};

export default PhoneForm;
