import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { Campaign } from "../types/types";
import TableComponent from "../components/TableComponent";
import LinkComponent from "../components/LinkComponent";

const CampaignList: React.FC = () => {
  const [campaignData, setCampaignData] = useState<Campaign[]>([]);

  useEffect(() => {
    axiosService
      .get(`${API}/campaigns/`)
      .then((response) => {
        setCampaignData(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/campaigns/${id}/`)
      .then((response) => {
        setCampaignData(campaignData.filter((campaign) => campaign.id !== id));
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <TableComponent
        search="name"
        data={campaignData}
        columns={[
          {
            key: "name",
            name: "Nazwa",
            className: "w-2/3",
          },
          {
            key: "status",
            name: "status",
          },
          {
            key: "created_at",
            name: "created At",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent text="Edycja" url={`/campaign/${id}`} />
                <LinkComponent
                  callback={() => handleDelete(id)}
                  text="Usuń"
                  url={`/campaigns`}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default CampaignList;
