import { Dropdown } from "flowbite-react";

interface IComponent {
  label: string;
  options: { id: number; name: string }[];
  callback?(option: { id: number; name: string }): void;
  className?: string;
}

const DropDownComponent = (props: IComponent) => {
  return (
    <Dropdown
      inline
      label={props.label}
      className={`w-48 overflow-y-auto max-h-60 ${props.className ?? ""}`}
    >
      {props.options?.map((option) => (
        <Dropdown.Item key={option.id} onClick={() => props.callback?.(option)}>
          {option.name}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default DropDownComponent;
