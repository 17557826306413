import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { InputType, StatusEnum, StatusOptionWithId } from "../const/Status";
import { API } from "../const/const";
import { Scenario, Candidate, Campaign, Phone } from "../types/types";
import TableComponent from "../components/TableComponent";
import LinkComponent from "../components/LinkComponent";
import FormComponent from "../components/FormComponent";
import UploadFileComponent from "../components/UploadFileComponent";
import ButtonComponent from "../components/ButtonComponent";
import { getHour, hourToUtc } from "../const/Time";
import { toBool } from "../const/Boolean";

const DEFAULT_CAMPAIGN_HOURS = {
  start: hourToUtc("9"),
  end: hourToUtc("18"),
};

const CampaignForm: React.FC = () => {
  const [campaignData, setCampaignData] = useState<Campaign>({
    id: -1,
    name: "",
    status: StatusEnum.INACTIVE,
    start_time: DEFAULT_CAMPAIGN_HOURS.start,
    end_time: DEFAULT_CAMPAIGN_HOURS.end,
    call_on_working_days: true,
    created_at: "",
    phones: [],
  });
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [phones, setPhones] = useState<Phone[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const getCandidates = (id: string) => {
    axiosService
      .get(`${API}/get_campaign_candidates/${id}/`)
      .then((res) => {
        setCandidates(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    axiosService
      .get(`${API}/phones`)
      .then((res) => {
        setPhones(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (id) {
      axiosService
        .get(`${API}/campaigns/${id}/`)
        .then((res) => {
          setCampaignData(res.data);
        })
        .catch((error) => console.log(error));
      axiosService
        .get(`${API}/get_campaign_scenarios/${id}/`)
        .then((res) => {
          setScenarios(res.data);
        })
        .catch((error) => console.log(error));
      getCandidates(id);
    }
  }, [id]);

  const handleSubmit = (formData: any) => {
    const campaignData = {
      name: formData.CampaignName,
      status: formData.CampaignStatus.name,
      start_time: hourToUtc(formData.CampaignStartTime),
      end_time: hourToUtc(formData.CampaignEndTime),
      call_on_working_days: toBool(formData.CampaignWorkingDay),
      phones: formData.CampaignPhones,
    };
    if (campaignData.name.trim() === "") return;
    if (id) {
      axiosService
        .put(`${API}/campaigns/${id}/`, campaignData)
        .then(() => {
          navigate("/campaigns");
        })
        .catch((error) => console.log(error));
    } else {
      axiosService
        .post(`${API}/campaigns/`, campaignData)
        .then(() => {
          navigate("/campaigns");
        })
        .catch((error) => console.log(error));
    }
  };

  const handleRemoveScenario = (id: number) => {
    axiosService
      .delete(`${API}/scenarios/${id}/`)
      .then((response) => {
        setScenarios(scenarios.filter((scenario) => scenario.id !== id));
      })
      .catch((error) => console.log(error));
  };

  const handleRemoveCandidate = (id: number) => {
    axiosService
      .delete(`${API}/candidates/${id}/`)
      .then((response) => {
        setCandidates(candidates.filter((candidate) => candidate.id !== id));
      })
      .catch((error) => console.log(error));
  };

  const handleUpload = (selectedFile: any) => {
    const fileData = new FormData();
    fileData.append("file", selectedFile);
    axiosService
      .post(`${API}/upload_candidates/${id}/`, fileData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (id) getCandidates(id);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  return (
    <div className="bg-gray-100 p-6">
      <FormComponent
        onSave={handleSubmit}
        buttonName={id ? "Edytuj" : "Dodaj"}
        fields={[
          {
            name: "CampaignName",
            label: "Nazwa kampanii",
            placeholder: "tu wpisz nazwę kampanii",
            require: true,
            type: InputType.TEXT,
            value: id ? campaignData.name : "",
          },
          {
            name: "CampaignStatus",
            label: "Status",
            type: InputType.DROPDOWN,
            options: StatusOptionWithId,
            value: id
              ? StatusOptionWithId.find((s) => s.name === campaignData.status)
              : StatusOptionWithId.find((s) => s.name === StatusEnum.INACTIVE),
          },
          {
            name: "CampaignStartTime",
            label: "Czas rozpoczęcia",
            type: InputType.TIME,
            value: getHour(campaignData.start_time).toString(),
          },
          {
            name: "CampaignEndTime",
            label: "Czas zakończenia",
            type: InputType.TIME,
            value: getHour(campaignData.end_time).toString(),
          },
          {
            name: "CampaignWorkingDay",
            label: "Dzwonić tylko w dni robocze",
            type: InputType.CHECKBOX,
            value: campaignData.call_on_working_days.toString(),
          },
          {
            name: "CampaignPhones",
            label: "Telefony dzwoniące",
            type: InputType.TAG,
            options: phones.map(({ id, name }) => ({ id, name })),
            value: campaignData.phones ? campaignData.phones : [],
          },
        ]}
      />
      <div className="mt-12">
        {scenarios.length > 0 && (
          <TableComponent
            search="name"
            data={scenarios}
            columns={[
              {
                key: "name",
                name: "Nazwa",
                className: "w-2/3",
              },
              {
                key: "status",
                name: "status",
                className: "w-1/3",
              },
              {
                key: "buttons",
                name: "",
                onRender: (scenarioId: number) => (
                  <div className="flex justify-end">
                    <LinkComponent
                      text="Edycja"
                      url={`/scenario/${scenarioId}/${id}`}
                    />
                    <LinkComponent
                      callback={() => handleRemoveScenario(scenarioId)}
                      text="Usuń"
                      url={`/campaign/${id}`}
                    />
                    <LinkComponent
                      text="Zaawansowany"
                      url={`/scenario_creator/${scenarioId}/${id}`}
                    />
                  </div>
                ),
              },
            ]}
          />
        )}
      </div>
      {id && (
        <div className="pb-24 pt-6">
          <Link to={`/scenario/new/${id}`}>
            <ButtonComponent text="Dodaj scenariusz" />
          </Link>
        </div>
      )}
      {candidates.length > 0 && (
        <TableComponent
          search="name"
          data={candidates}
          columns={[
            {
              key: "name",
              name: "Nazwa",
              className: "w-2/5",
            },
            {
              key: "phone",
              name: "Telefon",
              className: "w-1/5",
            },
            {
              key: "status",
              name: "Status",
              className: "w-1/5",
            },
            {
              key: "dialog_status",
              name: "Dialog status",
              className: "w-1/5",
            },
            {
              key: "call_attempts",
              name: "Próby połączeń",
              className: "w-1/5",
            },
            {
              key: "buttons",
              name: "",
              onRender: (candidate_id: number) => (
                <div className="flex justify-end">
                  <LinkComponent
                    text="Edycja"
                    url={`/campaign/${id}/candidate/${candidate_id}`}
                  />
                  <LinkComponent
                    callback={() => handleRemoveCandidate(candidate_id)}
                    text="Usuń"
                    url={`/campaign/${id}`}
                  />
                </div>
              ),
            },
          ]}
        />
      )}
      {id && (
        <div>
          <LinkComponent
            text="Dodaj pojedynczego kandydata"
            url={`/campaign/${id}/candidate/new`}
          />
          <UploadFileComponent
            types={".csv"}
            text="Dodaj kandydatów przez plik csv"
            buttonText="Załaduj plik"
            callback={handleUpload}
          />
        </div>
      )}
    </div>
  );
};

export default CampaignForm;
