import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputType, StatusEnum, StatusOptionWithId } from "../const/Status";

import { API } from "../const/const";
import ScenarioTree from "./ScenarioTree";
import FormComponent, { InvalidActionType } from "../components/FormComponent";
import ButtonComponent from "../components/ButtonComponent";

interface IQuestion {
  id: number;
  content: string;
  scenario: number;
}

interface IInvalidAction {
  id: number;
  content: string;
  lang: string;
}

type ID_INDEX = number | null;

interface IScenario {
  id: number;
  name: string;
  status: string;
  startQuestion: ID_INDEX;
  nothingEntered: number[];
  invalidButton: number[];
  repeatTimer: number;
  repeatTimes: number;
}

const DEFAULT_ELEMENT = "-----";
const EMPTY_QUESTION = { id: -1, content: DEFAULT_ELEMENT, scenario: -1 };

const ScenarioForm: React.FC = () => {
  const { id, campaign } = useParams();
  const [scenario, setScenario] = useState<IScenario>({
    id: -1,
    name: "",
    status: StatusEnum.INACTIVE,
    startQuestion: null,
    nothingEntered: [],
    invalidButton: [],
    repeatTimer: 5,
    repeatTimes: 1,
  });

  const [questions, setQuestions] = useState<IQuestion[]>([EMPTY_QUESTION]);
  const [invalidActions, setInvalidActions] = useState<IInvalidAction[]>([]);

  useEffect(() => {
    axiosService.get(`${API}/questions/`).then((response) => {
      const data: IQuestion[] = response.data;
      data.push(EMPTY_QUESTION);
      setQuestions(data.filter((q) => q.scenario.toString() === id));
    });
    axiosService.get(`${API}/invalid_actions/`).then((response) => {
      setInvalidActions(response.data);
    });

    if (id) {
      axiosService.get(`${API}/scenarios/${id}/`).then((response) => {
        const s = response.data;
        setScenario({
          id: s.id,
          name: s.name,
          status: s.status,
          startQuestion: s.start_question ?? EMPTY_QUESTION.id,
          nothingEntered: s.nothing_entered ? s.nothing_entered : [],
          invalidButton: s.invalid_button ? s.invalid_button : [],
          repeatTimer: s.repeat_timer,
          repeatTimes: s.repeat_times,
        });
      });
    }
  }, [id]);

  const handleSubmit = (formData: any) => {
    const scenario = {
      name: formData.ScenarioName,
      campaign,
      status: formData.ScenarioStatus.name,
      start_question:
        formData.startQuestion.id !== -1 ? formData.startQuestion.id : null,
      nothing_entered: formData.nothingEntered.map(
        (e: InvalidActionType) => e.id
      ),
      invalid_button: formData.invalidButton.map(
        (e: InvalidActionType) => e.id
      ),
      repeat_timer: formData.repeatTimer,
      repeat_times: formData.repeatTimes,
    };

    const url = id ? `${API}/scenarios/${id}/` : `${API}/scenarios/`;
    const method = id ? "put" : "post";
    axiosService({ method, url, data: scenario });
  };

  return (
    <div>
      <FormComponent
        onSave={handleSubmit}
        buttonName={id ? "Zapisz" : "Dodaj"}
        fields={[
          {
            name: "ScenarioName",
            label: "Nazwa Scenariusz",
            type: InputType.TEXT,
            placeholder: "nazwa scenariusz",
            require: true,
            value: id ? scenario.name : "",
          },
          {
            name: "ScenarioStatus",
            label: "Status",
            placeholder: "ACTIVE",
            type: InputType.DROPDOWN,
            options: StatusOptionWithId,
            value: StatusOptionWithId.find(
              (s) => s.name === (id ? scenario.status : StatusEnum.INACTIVE)
            ),
          },
          {
            name: "startQuestion",
            label: "Pytanie startowe",
            type: InputType.DROPDOWN,
            options:
              id && questions.length > 0
                ? questions.map((question) => ({
                    id: question.id,
                    name: question.content,
                  }))
                : [{ id: EMPTY_QUESTION.id, name: EMPTY_QUESTION.content }],
            value:
              id && questions.find((c) => c.id === scenario.startQuestion)
                ? {
                    name:
                      questions.find((c) => c.id === scenario.startQuestion)
                        ?.content ?? "",
                    id:
                      questions.find((c) => c.id === scenario.startQuestion)
                        ?.id ?? -1,
                  }
                : { name: EMPTY_QUESTION.content, id: EMPTY_QUESTION.id },
          },
          {
            name: "nothingEntered",
            label: "Nic nie wybrano",
            type: InputType.INVALID_ACTIONS,
            options: invalidActions.map((invalid) => ({
              id: invalid.id,
              name: invalid.content,
              lang: invalid.lang,
            })),
            value:
              id && scenario.nothingEntered
                ? scenario.nothingEntered.map((ne) => ({
                    name:
                      invalidActions.find((c) => c.id === ne)?.content ?? "",
                    id: invalidActions.find((c) => c.id === ne)?.id ?? -1,
                    lang: invalidActions.find((c) => c.id === ne)?.lang ?? "",
                  }))
                : [],
          },
          {
            name: "invalidButton",
            label: "Zły przycisk",
            type: InputType.INVALID_ACTIONS,
            options: invalidActions.map((invalid) => ({
              id: invalid.id,
              name: invalid.content,
              lang: invalid.lang,
            })),
            value:
              id && scenario.invalidButton
                ? scenario.invalidButton.map((ib) => ({
                    name:
                      invalidActions.find((c) => c.id === ib)?.content ?? "",
                    id: invalidActions.find((c) => c.id === ib)?.id ?? -1,
                    lang: invalidActions.find((c) => c.id === ib)?.lang ?? "",
                  }))
                : [],
          },
          {
            name: "repeatTimer",
            label: "Czas do powtórzenia",
            type: InputType.NUMBER,
            placeholder: "1",
            require: true,
            value: scenario.repeatTimer.toString(),
          },
          {
            name: "repeatTimes",
            label: "Ilość powtórzeń",
            type: InputType.NUMBER,
            placeholder: "1",
            require: true,
            value: scenario.repeatTimes.toString(),
          },
        ]}
      />
      {id && (
        <div className="pb-6 pt-24">
          <Link to={`/scenario_creator/${id}/${campaign}`}>
            <ButtonComponent text="Kreator Scenariusza" />
          </Link>
        </div>
      )}
      <div className="border-solid bg-gray-50">{id && <ScenarioTree />}</div>
    </div>
  );
};

export default ScenarioForm;
