import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { InternetConnection } from "../types/types";
import ReactApexChart from "react-apexcharts";
import moment from "moment";
import ButtonComponent from "../components/ButtonComponent";
import { Spinner } from "flowbite-react";

type ChartData = {
  options: {
    xaxis: {
      tickAmount: number;
    };
  };
  series: {
    name: string;
    data: { x: string; y: number; index: number }[];
  }[];
};

const InternetConnectionForm: React.FC = () => {
  const [chartData, setChartData] = useState<ChartData>();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [isLoader, setIsLoader] = useState<Boolean>(true);

  const handleStartDataChange = (event: any) => {
    const dateValue = event.target.value;
    const fullDateValue = new Date(`${dateValue}T00:00:00.0Z`);
    setStartDate(fullDateValue);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    const fullDateValue = new Date(`${dateValue}T23:59:59.0Z`);
    setEndDate(fullDateValue);
  };

  useEffect(() => {
    axiosService
      .get(`${API}/internet_connection`)
      .then((response) => {
        const transformedData = transformDataForChart(response.data);
        setChartData(transformedData);
        setIsLoader(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleFiltredData = () => {
    // TODO: Add get method to find date in range
    console.log(moment.utc(startDate).toISOString());
    console.log(moment.utc(endDate).toISOString());
    setIsLoader(true);
    setTimeout(() => setIsLoader(false), 10000);
  };

  const transformDataForChart = (data: InternetConnection[]) => {
    const formattedData = data.map((entry) => ({
      x: moment.utc(entry.created_at).format("YYYY-MM-DD HH:mm:ss"),
      y: entry.negative_pings,
      index: entry.id,
    }));

    return {
      options: {
        xaxis: {
          tickAmount: 20,
        },
      },
      series: [
        {
          name: "Ilośc negatywnych pingów",
          data: formattedData,
        },
      ],
    };
  };

  return (
    <div>
      <div className="mb-12">
        <h1>Zakres wyświetlania</h1>
        <div className="flex">
          <div className="flex items-center">
            <h1 className="pr-2">Od</h1>
            <input type="date" onChange={handleStartDataChange} />
          </div>
          <div className="flex items-center">
            <h1 className="px-2">Do</h1>
            <input type="date" onChange={handleEndDateChange} />
          </div>
          <div className="pl-6">
            <ButtonComponent text="Wyświetl" callback={handleFiltredData} />
          </div>
        </div>
      </div>
      {isLoader ? (
        <div className="flex items-center">
          <h1 className="pr-6 text-lg">Pobieranie danych</h1>
          <Spinner aria-label="Extra large spinner example" size="xl" />
        </div>
      ) : (
        <ReactApexChart
          options={chartData?.options}
          series={chartData?.series}
          type="line"
          height={400}
        />
      )}
    </div>
  );
};

export default InternetConnectionForm;
