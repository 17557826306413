import React from "react";
import { Scenario, QuestionFull, InvalidAction } from "../types/types";

interface IScenarioInfoProps {
  info: Scenario;
  questions: QuestionFull[];
  invalidActions: InvalidAction[];
  onChange: (i: Scenario) => void;
}

function ScenarioInfo(props: IScenarioInfoProps) {
  // TODO: fix it
  const updateField = (
    field: keyof Scenario,
    value: string | number | null
  ) => {
    props.onChange({
      ...props.info,
      [field]: value,
    });
  };

  return (
    <div className="bg-gray-200 p-3">
      <div> Created At: {props.info.created_at}</div>
      <div className="flex">
        <label>Name: </label>
        <h1 className="ml-5">{props.info.name}</h1>
      </div>
      <div className="flex">
        <label>Status: </label>
        <h1 className="ml-5">{props.info.status}</h1>
      </div>
      <div className="flex">
        <label>Start Question: </label>
        <h1 className="ml-5">{props.info.start_question}</h1>
      </div>
      <div className="flex">
        <label>Nothing Entered: </label>
        <h1 className="ml-5">{props.info.nothing_entered}</h1>
      </div>
      <div className="flex">
        <label>Invalid Button: </label>
        <h1 className="ml-5">{props.info.invalid_button}</h1>
      </div>
      <div className="flex">
        <label>Repeat Timer: </label>
        <h1 className="ml-5">{props.info.repeat_timer}</h1>
      </div>
      <div className="flex">
        <label>Repeat Times: </label>
        <h1 className="ml-5">{props.info.repeat_times}</h1>
      </div>
    </div>
  );
}

export default ScenarioInfo;
