import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";

import { API } from "../const/const";
import TableComponent from "../components/TableComponent";
import { Tag } from "../types/types";
import LinkComponent from "../components/LinkComponent";
import ButtonComponent from "../components/ButtonComponent";

const TagList: React.FC = () => {
  const [tags, setTags] = useState<Tag[]>([]);

  const getTags = () => {
    axiosService
      .get(`${API}/tags/`)
      .then((response) => {
        setTags(response.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getTags();
  }, []);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/tags/${id}/`)
      .then((response) => {
        setTags(tags.filter((tag) => tag.id !== id));
      })
      .catch((error) => console.log(error));
  };

  const handleSync = () => {
    axiosService
      .post(`${API}/tags_sync/`)
      .then((resp) => {
        getTags();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <ButtonComponent text="Synchronizacja" callback={handleSync} />
      <TableComponent
        search="name"
        data={tags}
        columns={[
          {
            key: "tag",
            name: "Tag",
            className: "w-2/3",
          },
          {
            key: "created_at",
            name: "Created at",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent text="Edycja" url={`/tag/${id}`} />
                <LinkComponent
                  callback={() => handleDelete(id)}
                  text="Usuń"
                  url={`/tags`}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default TagList;
