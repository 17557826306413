import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Checkbox, Label, TextInput } from "flowbite-react";
import { useFormik } from "formik";

import ButtonComponent from "../components/ButtonComponent";
import authSlice from "../store/slices/auth";
import { API } from "../const/const";
import logo from "../assets/weegree-logo.png";

const LoginScreen: React.FC = () => {
  const [loginError, setLoginError] = useState<string>("");
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: async (values) => {
      setIsLoginButtonDisabled(true);
      axios
        .post(`${API}/auth/login/`, {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          dispatch(
            authSlice.actions.setAuthTokens({
              token: res.data.access,
              refreshToken: res.data.refresh,
            })
          );
          dispatch(authSlice.actions.setAccount(res.data.user));
          setIsLoginButtonDisabled(false);
          setLoginError("");
          navigate("/");
        })
        .catch((err) => {
          setLoginError(err.message);
          setIsLoginButtonDisabled(false);
        });
    },
  });

  return (
    <div className="bg-gray-100 h-screen">
      <div className="pb-40 flex flex-col justify-center items-center h-screen ">
        <div className="pb-12 flex justify-center">
          <img className="h-auto max-w-xs" src={logo} alt="weegree logo" />
        </div>
        <form
          className="bg-white p-5 rounded-lg w-2/5"
          onSubmit={formik.handleSubmit}
        >
          <h1 className="text-4xl font-bold pb-8">Zaloguj się</h1>
          <div>
            <Label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              value="Twój email"
            />
            <TextInput
              type="email"
              id="email"
              placeholder="email@voicebot.com"
              {...formik.getFieldProps("email")}
            />
          </div>
          <div className="mb-6 pt-4">
            <Label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              value="Hasło"
            />
            <TextInput
              {...formik.getFieldProps("password")}
              type="password"
              id="password"
            />
            {loginError.length > 0 && (
              <div className="mt-2 text-red-600">{loginError}</div>
            )}
          </div>
          <div className="flex items-start mb-6 cursor-not-allowed opacity-20">
            <div className="flex items-center h-5">
              <Checkbox
                id="remember"
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
              />
            </div>
            <Label
              htmlFor="remember"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              value="Zapamiętaj mnie"
            />
          </div>
          <ButtonComponent
            text="Zaloguj się"
            disabled={isLoginButtonDisabled}
            type="submit"
            className="w-full"
          />
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
