import React, { useState, useEffect } from "react";
import { API } from "../const/const";
import axiosService from "../utils/axios";

import TableComponent from "../components/TableComponent";
import { User } from "../types/types";
import LinkComponent from "../components/LinkComponent";

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    axiosService
      .get(`${API}/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <TableComponent
        search="username"
        data={users}
        columns={[
          {
            key: "username",
            name: "Nazwa",
          },
          {
            key: "email",
            name: "Email",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent
                  text="Reset password"
                  url={`/reset_password/${id}`}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Users;
