import { Button } from "flowbite-react";

interface IButton {
  text: string;
  callback?(): void;
  disabled?: boolean;
  className?: string;
  type?: string;
  color?: string;
}

const ButtonComponent = (props: IButton) => {
  return (
    <Button
      type={props.type}
      color={props.color ?? "purple"}
      disabled={props.disabled}
      className={props.className}
      onClick={() => props.callback?.()}
    >
      {props.text}
    </Button>
  );
};

export default ButtonComponent;
