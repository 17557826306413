import React from "react";
import { Sidebar } from "flowbite-react";
import { MenuItem, Role } from "../../types/types";

type MenuItemsProps = {
  userPermission: Role;
  menuItemsData: MenuItem[];
};

export function MenuItems({ userPermission, menuItemsData }: MenuItemsProps) {
  return (
    menuItemsData.length > 0 && (
      <Sidebar.ItemGroup>
        <h5
          id="drawer-navigation-label"
          className="p-2 text-base font-semibold text-gray-500 uppercase dark:text-gray-400"
        >
          Menu
        </h5>
        {menuItemsData.map((menuItem, index) => (
          <React.Fragment key={index}>
            {menuItem.permission.some((perm) =>
              userPermission.includes(perm)
            ) &&
              (menuItem.subItems ? (
                <Sidebar.Collapse icon={menuItem.icon} label={menuItem.label}>
                  {menuItem.subItems.map((subItem, subIndex) => (
                    <Sidebar.Item
                      key={subIndex}
                      href={subItem.href}
                      className={
                        subItem.href === "#" && "cursor-not-allowed opacity-20"
                      }
                    >
                      <p>{subItem.label}</p>
                    </Sidebar.Item>
                  ))}
                </Sidebar.Collapse>
              ) : (
                <Sidebar.Item
                  icon={menuItem.icon}
                  href={menuItem.href}
                  className={
                    menuItem.href === "#" && "cursor-not-allowed opacity-20"
                  }
                >
                  <p>{menuItem.label}</p>
                </Sidebar.Item>
              ))}
          </React.Fragment>
        ))}
      </Sidebar.ItemGroup>
    )
  );
}
