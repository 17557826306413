import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";
import { useParams } from "react-router-dom";
import { API } from "../const/const";
import { getAudioFileName } from "../utils/utils";

interface Audio {
  id: number;
  audio_name: string;
  audio_file: string;
  created_at: string;
}

const AudioEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [audio, setAudio] = useState<Audio | null>(null);
  const [audioName, setAudioName] = useState("");
  const [audioFile, setAudioFile] = useState<File | null>(null);

  useEffect(() => {
    axiosService
      .get(`${API}/audios/${id}`)
      .then((response) => {
        console.log(response.data);
        setAudio(response.data);
        setAudioName(response.data.audio_name);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("audio_name", audioName);
    if (audioFile) {
      formData.append(
        "audio_file",
        audioFile,
        getAudioFileName(audioName, audioFile.name)
      );
    }
    axiosService
      .put(`${API}/audios/${id}/`, formData)
      .then((response) => {
        setAudio(response.data);
      })
      .catch((error) => console.log(error));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setAudioFile(event.target.files[0]);
    }
  };

  return (
    <div>
      <h1>Edit Audio</h1>
      {audio && (
        <form onSubmit={handleSubmit}>
          <label>
            Audio Name:
            <input
              type="text"
              value={audioName}
              onChange={(event) => setAudioName(event.target.value)}
            />
          </label>
          <label>
            Audio File:
            <input type="file" accept=".mp3" onChange={handleFileChange} />
          </label>
          <input type="submit" value="Save" />
        </form>
      )}
    </div>
  );
};

export default AudioEdit;
