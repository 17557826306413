import React from "react";
import type { CustomFlowbiteTheme } from "flowbite-react";
import { Avatar, Sidebar } from "flowbite-react";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { MenuItems } from "../components/Sidebar/MenuItems";
import { menuItemsData } from "../components/Sidebar/MenuItemsData";
import authSlice from "../store/slices/auth";
import { RootState } from "../store";
import logo from "../assets/weegree-logo.png";
import { Role } from "../types/types";

const customTheme: CustomFlowbiteTheme["sidebar"] = {
  root: {
    base: "fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700",
    inner:
      "h-full overflow-y-auto overflow-x-hidden rounded bg-white py-4 px-3 dark:bg-gray-800",
  },
};

const CustomSidebar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const account = useSelector((state: RootState) => state.auth.account);
  const userPermission: Role = account ? (account?.role as Role) : Role.User;
  return (
    <Sidebar theme={customTheme}>
      <Sidebar.Logo href="/" img={logo} />
      <Sidebar.ItemGroup className="border-0">
        <div className="bottom-0 left-0 px-3 py-4 w-full z-20">
          <Sidebar.Item href="#">
            <Avatar rounded>
              <div className="space-y-1 font-medium dark:text-white">
                <div>{account ? account.username : "-----"}</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {account ? account.email : "-----"}
                </div>
              </div>
            </Avatar>
          </Sidebar.Item>
          <div
            onClick={() => {
              dispatch(authSlice.actions.setLogout());
              navigate("/login");
            }}
          >
            <Sidebar.Item href="#" icon={BiLogOut}>
              <p>Wyloguj się</p>
            </Sidebar.Item>
          </div>
          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        </div>
      </Sidebar.ItemGroup>
      <Sidebar.Items>
        {MenuItems({ userPermission, menuItemsData })}
      </Sidebar.Items>
    </Sidebar>
  );
};

export default CustomSidebar;
