import React, { useEffect } from "react";
import ReactFlow, { Controls, useNodesState, useEdgesState } from "reactflow";
import axiosService from "../utils/axios";
import { useParams } from "react-router-dom";

import { getLayoutedElements } from "../utils/utils";
import { createNode, createEdge } from "../utils/tree";
import { Question, Answer, CustomNode, CustomEdge } from "../types/types";
import { API } from "../const/const";

import "reactflow/dist/style.css";

const nodeWidth = 480;
const nodeHeight = 100;

const ScenarioTree: React.FC = () => {
  const { id } = useParams();

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  useEffect(() => {
    axiosService
      .get(`${API}/get_scenario_by_id/${id}/`)
      .then((response) => {
        console.log("data", response.data);
        // const scenario: Scenario  = response.data.scenario; // TODO: add more info about scenario
        const questions: Question[] = response.data.questions;
        const answers: Answer[] = response.data.answers;

        const questionNodes = questions.map((q) =>
          createNode(`q${q.id}`, q.content, nodeWidth, nodeHeight)
        );
        const answerNodes = answers.map((a) =>
          createNode(`a${a.id}`, a.content, nodeWidth, nodeHeight)
        );
        const _nodes: CustomNode[] = [...questionNodes, ...answerNodes];

        const questionEdges = answers
          .filter((a) => a.question !== null)
          .map((a) => createEdge(`q${a.question}`, `a${a.id}`));
        const nextQuestionEdges = answers
          .filter((a) => a.next_question !== null)
          .map((a) => createEdge(`a${a.id}`, `q${a.next_question}`));
        const autoQuestionEdges = questions
          .filter((q) => q.next_question !== null)
          .map((q) => createEdge(`q${q.id}`, `q${q.next_question}`));

        const _edges: CustomEdge[] = [
          ...questionEdges,
          ...nextQuestionEdges,
          ...autoQuestionEdges,
        ];

        const { nodes: sortedNodes, edges: sortedEdges } = getLayoutedElements(
          _nodes,
          _edges,
          nodeWidth,
          nodeHeight
        );

        setNodes(sortedNodes);
        setEdges(sortedEdges);
      })
      .catch((error) => console.log(error));
  }, [id, setEdges, setNodes]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ReactFlow nodes={nodes} edges={edges}>
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default ScenarioTree;
