import { IconType } from "react-icons/lib";

export interface Scenario extends Map {
  id: number;
  name: string;
  campaign: number;
  created_at: string;
  status: string;
  start_question: number | null; // TODO: do refactor create type Index
  nothing_entered: number | null;
  invalid_button: number | null;
  repeat_timer: number;
  repeat_times: number;
}

export interface Campaign extends Map {
  id: number;
  name: string;
  created_at: string;
  status: string;
  start_time: string;
  end_time: string;
  call_on_working_days: boolean;
  phones: number[];
}

export interface User extends Map {
  id: number;
  username: string;
  email: string;
}

export interface Question extends Map {
  id: number;
  content: string;
  lang: string;
  scenario: number | null;
  created_at: string;
  next_question: number | null;
  audio: number | null;
}

export interface QuestionFull extends Map {
  id: number;
  content: string;
  lang: string;
  scenario: number | null;
  created_at: string;
  next_question: number | null;
  audio: Audio | null;
}

export interface Answer extends Map {
  id: number;
  content: string;
  button: number;
  question: number | null;
  next_question: number | null;
  tags: number[];
  created_at: string;
}

export interface Audio {
  id: number;
  audio_name: string;
  audio_file: string;
  created_at: string;
}

export interface Tag extends Map {
  id: number;
  tag: string;
  created_at: string;
}

export interface InvalidAction extends Map {
  id: number;
  content: string;
  lang: string;
  create_at: string;
  audio_invalid: number | null;
}

export interface CustomNode {
  id: string;
  position: {
    x: number;
    y: number;
  };
  data: {
    label: string;
  };
  style?: {
    width?: number;
    height?: number;
    fontSize?: number;
  };
}

export interface CustomEdge {
  id: string;
  source: string;
  target: string;
  type?: string;
}

export type FieldType =
  | Audio
  | string
  | undefined
  | number
  | boolean
  | number[]
  | null;

export interface Map {
  id: number;
  [key: string]: FieldType;
}

export type Column = {
  key: string;
  name: string;
  className?: string;
  onRender?: (id: number) => React.JSX.Element;
};

export type MenuItem = {
  href?: string;
  icon: IconType;
  label: string;
  subItems?: {
    href: string;
    label: string;
  }[];
  permission: Role[];
};

export enum Role {
  SuperAdmin = "SUPER_ADMIN",
  Admin = "ADMIN",
  User = "USER",
}

export interface Phone extends Map {
  id: number;
  name: string;
  phone: string;
  status: PhoneStatus;
  created_at: string;
  call_status: string;
}

export enum PhoneStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export interface Candidate extends Map {
  id: number;
  call_attempts: number;
  campaign: number | null;
  name: string;
  phone: string;
  status: CandidateStatus;
  sid: string;
  dialog_status: DialogStatus;
  created_at: string;
  info: string;
}

export interface InternetConnection extends Map {
  id: number;
  negative_pings: number;
  created_at: string;
}

export enum CandidateStatus {
  PENDING = "PENDING",
  INITIATED = "INITIATED",
  RINGING = "RINGING",
  INPROGRESS = "IN-PROGRESS",
  COMPLETED = "COMPLETED",
  BUSY = "BUSY",
  FAILED = "FAILED",
  NOANSWER = "NO-ANSWER",
  CANCELED = "CANCELED",
  OTHER = "OTHER",
}

export enum CallStatus {
  INITIATED = "INITIATED",
  RINGING = "RINGING",
  INPROGRESS = "IN-PROGRESS",
  COMPLETED = "COMPLETED",
  OTHER = "OTHER",
}

export enum DialogStatus {
  PENDING = "PENDING",
  UNSUCCESSFUL = "UNSUCCESSFUL",
  QUALIFIED = "QUALIFIED",
  INCOMPLETE = "INCOMPLETE",
  OTHER = "OTHER",
}
