import dagre from "dagre";
import { Edge, Node, Position } from "reactflow";

export const getLayoutedElements = (
  nodes: Node<{ label: string }, string | undefined>[],
  edges: Edge<any>[],
  nodeWidth: number,
  nodeHeight: number,
  direction = "TB"
) => {
  const isHorizontal = direction === "LR";

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export const getAudioFileName = (name: string, filename: string): string => {
  const now = new Date();
  const current = now.toISOString().replace(/:/g, "-");
  return `${name}_${current}_${filename}`;
};
