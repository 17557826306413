import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import axiosService from "../utils/axios";
import { API } from "../const/const";
import { User } from "../types/types";

// TODO: Screen for styling
const ResetPassword: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  const handleReset = () => {
    axiosService
      .post(`${API}/reset_password/`, {
        user_id: id,
        new_password: password1,
      })
      .then(() => {
        navigate("/users");
      })
      .catch((err) => {
        console.log(`Reset password with error: ${err}`);
      });
  };

  useEffect(() => {
    axiosService
      .get(`${API}/users/${id}/`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => {
        console.log(`Get user data with error: ${err}`);
      });
  }, [id]);

  return (
    <div>
      <h2>Reset password</h2>
      {user ? (
        <div>
          {`User: ${user?.username} Email: ${user?.email}`}
          <br />
          Password:{" "}
          <input
            type="password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
          />
          <br />
          Repeat password:{" "}
          <input
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
          <br />
          <button
            onClick={() => handleReset()}
            disabled={password1 !== password2 || password1.length === 0}
          >
            Reset password
          </button>
        </div>
      ) : (
        <>User is undefined</>
      )}
    </div>
  );
};

export default ResetPassword;
