import moment from "moment";

export const getHoursInDay = () => {
  let hourEnum: { [key: string]: string } = {};
  Array.from(
    { length: 24 },
    (_, index) => (hourEnum[index.toString()] = index.toString())
  );
  return hourEnum;
};

export const HourList = () => {
  const HoursEnum = getHoursInDay();
  return (Object.keys(HoursEnum) as Array<keyof typeof HoursEnum>).map(
    (key, index) => ({
      id: index,
      name: HoursEnum[key],
    })
  );
};

export const getHour = (time: string): number => moment(time).utc().hour();

export const hourToUtc = (hour: string): string =>
  moment
    .utc()
    .hour(Number(hour))
    .minute(0)
    .second(0)
    .format("YYYY-MM-DDTHH:mm:ss[Z]");
