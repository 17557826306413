import { CustomEdge, CustomNode } from "../types/types";

export const createNode = (
  id: string,
  content: string,
  width: number,
  height: number
): CustomNode => {
  const position = { x: 0, y: 0 };
  return {
    id,
    position,
    data: { label: content },
    style: { width, height },
  };
};

export const createEdge = (sourceId: string, targetId: string): CustomEdge => {
  return {
    id: `e${sourceId}-${targetId}`,
    source: sourceId,
    target: targetId,
    type: "step",
  };
};
