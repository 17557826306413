import { Link } from "react-router-dom";

interface ILinkComponent {
  text: string;
  url: string;
  callback?(): void;
  style?: string;
}

const LinkComponent = ({ text, url, callback, style }: ILinkComponent) => (
  <Link
    onClick={() => callback?.()}
    className={
      style ??
      "font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-2 ml-2"
    }
    to={url}
  >
    {text}
  </Link>
);

export default LinkComponent;
