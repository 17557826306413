import { TextInput } from "flowbite-react";

interface ITextInputComponent {
  classname?: string;
  id: string;
  name: string;
  type: string;
  placeholder?: string;
  require?: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  value?: string | number;
}

const TextInputComponent = (props: ITextInputComponent) => {
  return (
    <TextInput
      className={props.classname}
      id={props.name}
      name={props.name}
      type={props.type}
      placeholder={props.placeholder}
      required={props.require}
      onChange={(e) => props.onChange(e)}
      value={props.value}
    />
  );
};

export default TextInputComponent;
