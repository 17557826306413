import React, { useState, useEffect } from "react";
import axiosService from "../utils/axios";

import { API } from "../const/const";
import TableComponent from "../components/TableComponent";
import { Phone } from "../types/types";
import LinkComponent from "../components/LinkComponent";

const PhoneList: React.FC = () => {
  const [phones, setPhones] = useState<Phone[]>([]);

  useEffect(() => {
    axiosService
      .get(`${API}/phones/`)
      .then((response) => {
        setPhones(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleDelete = (id: number) => {
    axiosService
      .delete(`${API}/phones/${id}/`)
      .then((response) => {
        setPhones(phones.filter((phone) => phone.id !== id));
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <TableComponent
        search="name"
        data={phones}
        columns={[
          {
            key: "name",
            name: "Nazwa",
            className: "w-1/3",
          },
          {
            key: "phone",
            name: "Phone",
            className: "w-1/3",
          },
          {
            key: "status",
            name: "status",
            className: "w-1/3",
          },
          {
            key: "call_status",
            name: "Status dzwonienia",
            className: "w-1/3",
          },
          {
            key: "buttons",
            name: "",
            onRender: (id: number) => (
              <div className="flex justify-end">
                <LinkComponent text="Edycja" url={`/phone/${id}`} />
                <LinkComponent
                  callback={() => handleDelete(id)}
                  text="Usuń"
                  url={`/phones`}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default PhoneList;
