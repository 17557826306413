import React, { useState } from "react";
import axiosService from "../utils/axios";
import { API } from "../const/const";
import { getAudioFileName } from "../utils/utils";

const AudioForm: React.FC = () => {
  const [audioName, setAudioName] = useState("");
  const [audioFile, setAudioFile] = useState<File | null>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!audioFile) {
      alert("Please select an audio file.");
      return;
    }

    const formData = new FormData();
    formData.append("audio_name", audioName);
    formData.append(
      "audio_file",
      audioFile,
      getAudioFileName(audioName, audioFile.name)
    );

    axiosService
      .post(`${API}/audios/`, formData)
      .then(() => {
        alert("Audio uploaded successfully.");
        setAudioName("");
        setAudioFile(null);
      })
      .catch((error) => {
        alert("An error occurred. Please try again.");
        console.log(error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Audio Name:
        <input
          type="text"
          value={audioName}
          onChange={(event) => setAudioName(event.target.value)}
          required
        />
      </label>

      <label>
        Audio File:
        <input
          type="file"
          accept="audio/*"
          onChange={(event) =>
            setAudioFile(event.target.files ? event.target.files[0] : null)
          }
          required
        />
      </label>

      <button type="submit">Upload</button>
    </form>
  );
};

export default AudioForm;
